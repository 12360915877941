import React from "react";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useGetMerchant, useUpdateMerchant } from "../apiHooks";
import Card from "../../../components/atoms/Card";
import { Button, Column, Grid, Row } from "carbon-components-react";
import { useHistory, useParams } from "react-router-dom";
import { createEditValidation } from "../validation";
import { useCreateEditConfig } from "../constantHooks";
import Loader from "components/atoms/Loader";
import { usePrepareDataForMerchantIpn } from "../hooks";
import { APP_ROUTES } from "system/router/constants";


const Update: React.FC = () => {
  const isEdit = true;
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { mutateAsync: onUpdate } = useUpdateMerchant();
  const { data: merchantData, isLoading } = useGetMerchant(routeParams?.id);
  const { getIpnsUrl, normalizeDataBeforeSave } = usePrepareDataForMerchantIpn()
  const normalizeMerchantData = getIpnsUrl(merchantData)
  const formConfig = useCreateEditConfig(normalizeMerchantData);
  const history = useHistory()
  const onSubmit = async (data: any) => {
    const { status } = await onUpdate({ data: normalizeDataBeforeSave(data), id: routeParams?.id });
    status === "APPROVED" && history.length > 1 ? history.goBack() : history.replace(APP_ROUTES.merchants.index);
  };
  /*
  const onDeactivate = () => {
    onUpdate({ data: { ...merchantData, disable: true }, id: routeParams?.id });
  };
*/

  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card
            title={_t("edit")}
            extra={<Button kind={"tertiary"}>{_t("deactivate")}</Button>}
          >
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                submitBtnLabel: _t("save_changes"),
                submitBtnKind: "primary",
                onSubmit: onSubmit,
                initialValues: { ...normalizeMerchantData },
                validationSchema: createEditValidation(_t, {
                  isEdit,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;
