import { Button } from "carbon-components-react";
import { FlightInternational16 } from "@carbon/icons-react";
import { useTrans } from "system/translations/hooks";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import ResendIpn from "./ResendIpn";


const TransactionInfoFooter = ({ resendIpnId, transactionLogsUrl }: { resendIpnId: string, transactionLogsUrl: string | null }) => {
  const { _t } = useTrans()
  return (
    <>
      {!!transactionLogsUrl && (
        <RoleWrapper accessTo={"ROLE_VIEW_LOG_BUTTONS"}>
          <Button
            kind="tertiary"
            onClick={() => window.open(transactionLogsUrl, "_blank")}
            renderIcon={FlightInternational16}
          >
            {_t("transaction_logs")}
          </Button>
        </RoleWrapper>
      )}
      <ResendIpn id={resendIpnId} />
    </>
  );
};

export default TransactionInfoFooter;
