import React, { useEffect, useState } from "react";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import { Column, Grid, Row } from "carbon-components-react";
import { useCreatePaymentOrchestrationItem } from "modules/routingRules/apiHooks";
import _ from "lodash";
import PaymentOrchestrationTreeViewRule from "modules/routingRules/components/RoutingRulesTreeView";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useCreateEditConfig } from "../constantHooks";
import Divider from "components/atoms/Divider";
import { createEditOrchestratorValidation } from "../validation";
import { useGetUpdateRoutingRulesTreeItems, useGetWarningSaveDataWithoutDefaultStartegy } from "../hooks";
import { initialValue } from "../constants";

const Create: React.FC = () => {
  const { _t } = useTrans();
  const { mutate: onCreate } = useCreatePaymentOrchestrationItem()
  const [ selectedMerchantId, setSelectedMerchant ] = useState<number | undefined>()
  const formConfig = useCreateEditConfig({ isEdit: false, onChangeMerchant: setSelectedMerchant, selectedMerchantId: selectedMerchantId })
  const [rules, setRules] = useState(initialValue)
  const { checkDefaultStrategyAndDisplayWarningOrSaveData } = useGetWarningSaveDataWithoutDefaultStartegy()
  const { removeAllProviderValueInStrategys } = useGetUpdateRoutingRulesTreeItems(setRules, rules)

  const onSubmit = (formData: any) => {
    const normalize = { ...formData }
    normalize.rules = JSON.stringify(rules)
    checkDefaultStrategyAndDisplayWarningOrSaveData(rules, () => onCreate(normalize))
  }

  useEffect(() => {
    removeAllProviderValueInStrategys(rules.token)
    removeAllProviderValueInStrategys(rules.newInstrument)
  },[selectedMerchantId] )

  return (
    <Grid style={{ paddingLeft: "1rem" }} narrow>
      <Row>
        <Column
          lg={{ span: 16, offset: 0 }}
          md={{ span: 16, offset: 0 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t('create_a_new_rule_set')}>
            {_t("rule_set_configuration")}
            
            <FormBuilder
              showSubmit={false}
              formItemsConfig={formConfig}
              formProps={{
                onSubmit: onSubmit,
                initialValues: {},
                validationSchema: createEditOrchestratorValidation(_t)
              }}
            />

            <PaymentOrchestrationTreeViewRule value={rules} onChange={setRules} selectedMerchantId={selectedMerchantId} />
            <div className='tree-view-rule__divider'>
            <Divider/>
            </div>
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Create;
